import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/Logitech_Harmony/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Logitech Harmony in Home Assistant via INSTAR MQTT",
  "path": "/Software/Home_Automation/Logitech_Harmony/Home_Assistant/",
  "dateChanged": "2020-03-11",
  "author": "Mike Polinowski",
  "excerpt": "Logitech Harmony",
  "social": "/images/Search/P_SearchThumb_Harmony.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Harmony_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Home Assistant"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Logitech Harmony in Home Assistant über INSTAR MQTT' dateChanged='2020-03-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Die Logitech Harmony ist eine Universal-Fernbedienung, die programmiert werden kann, dass sie nicht nur mit Ihrem Fernseher, sondern auch mit Ihren Smarthome-Komponenten kommuniziert. Die folgenden Tutorials zeigen Ihnen, wie Sie Fakeroku auf Ihrem Smarthome-System installieren, um eine virtuelle Schnittstelle zu schaffen, mit der der Logitech Harmony Hub sich verbinden kann.' image='/images/Search/P_SearchThumb_Harmony.png' twitter='/images/Search/P_SearchThumb_Harmony.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/Logitech_Harmony/Home_Assistant/' locationFR='/fr/Home_Automation/Logitech_Harmony/Home_Assistant/' crumbLabel="Home Assistant" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <h3 {...{
      "id": "logitech-harmony-with-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#logitech-harmony-with-home-assistant",
        "aria-label": "logitech harmony with home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logitech Harmony with Home Assistant`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#emulatedroku-auf-dem-home-assistenten-installieren"
        }}>{`EmulatedRoku auf dem Home-Assistenten installieren`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instar-mqtt-setup"
        }}>{`INSTAR MQTT Setup`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schwenken-und-neigen-der-kamera"
            }}>{`Schwenken und Neigen der Kamera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schalten-sie-ihren-alarm-scharf-bevor-sie-ihr-haus-verlassen"
            }}>{`Schalten Sie Ihren Alarm scharf, bevor Sie Ihr Haus verlassen`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#starten-sie-eine-manuelle-videoaufzeichnung"
            }}>{`Starten Sie eine manuelle Videoaufzeichnung`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#einen-alarm-ausl%C3%B6sen"
            }}>{`Einen Alarm auslösen`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#schlussfolgerung"
        }}>{`Schlussfolgerung`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#automationsyaml-export"
        }}>{`automations.yaml export`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "emulatedroku-auf-dem-home-assistenten-installieren",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#emulatedroku-auf-dem-home-assistenten-installieren",
        "aria-label": "emulatedroku auf dem home assistenten installieren permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`EmulatedRoku auf dem Home-Assistenten installieren`}</h2>
    <p>{`Wir werden nun ein emuliertes Roku-Gerät im Home Assistant installieren, das uns die Einrichtung einer Schnittstelle mit dem Logitech Harmony Hub ermöglicht. Öffnen Sie dafür die Registerkarte `}<strong parentName="p">{`Integration`}</strong>{` im Menü `}<strong parentName="p">{`Konfiguration`}</strong>{` und richten Sie die Erweiterung `}<strong parentName="p">{`EmulatedRoku`}</strong>{` ein.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5dafaaeba0195d895327533648d159b/1cfc2/Home_Assistant_Logitech_Harmony_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABnElEQVQoz1WQb2/SUBSH+ZBGR9W299xzb6srrGm5XZv41YxZfDE2kQwGMRRZg7udoGOjfyw1GWhsccYn58V58zvnOacBAIQQTdNUVdU0jVWYnBuUIeArxhGRUso5BwDGGCJFRM45Y6xBKwCgbohONF1HVW0fNNsHivX8hV5RL8C/VFOwUccAwDAM0zQ7nY5t21ardeQ4wvddIdwKx3Fc160zjzQIIQCAiADgB8E8imafZ1EUhWE4Ho+llHmep2maZVkcx0IIQkjtvNeuzyYEgsCXt/dJlm82RVEUeZ6XP8vtbvuwfdj92q2TxPO82nGvres6pZQxRgCC4+PreLH4drdOsjQr0qxI0h/rJP9TaR7HXz1P1OH9w0zOOGMGIgEStNo3J+8Xvf794LKczopwWoZhOQnLyaSczhanXWFZgPhvs0JZk+IzHZ6omuP5m+Uyl3J1dZXM55mUqy/yTt7cXsfl91WxXHpC/KdtKmpLefla0Q6fNt8cWsPR6GI47J6fn551z3q9k/7lu/747cfRh9Gni8HgyLYJIY8//w1azzCLepSrCQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5dafaaeba0195d895327533648d159b/e4706/Home_Assistant_Logitech_Harmony_01.avif 230w", "/en/static/a5dafaaeba0195d895327533648d159b/d1af7/Home_Assistant_Logitech_Harmony_01.avif 460w", "/en/static/a5dafaaeba0195d895327533648d159b/9b8d4/Home_Assistant_Logitech_Harmony_01.avif 900w"],
              "sizes": "(max-width: 900px) 100vw, 900px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5dafaaeba0195d895327533648d159b/a0b58/Home_Assistant_Logitech_Harmony_01.webp 230w", "/en/static/a5dafaaeba0195d895327533648d159b/bc10c/Home_Assistant_Logitech_Harmony_01.webp 460w", "/en/static/a5dafaaeba0195d895327533648d159b/131f1/Home_Assistant_Logitech_Harmony_01.webp 900w"],
              "sizes": "(max-width: 900px) 100vw, 900px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5dafaaeba0195d895327533648d159b/81c8e/Home_Assistant_Logitech_Harmony_01.png 230w", "/en/static/a5dafaaeba0195d895327533648d159b/08a84/Home_Assistant_Logitech_Harmony_01.png 460w", "/en/static/a5dafaaeba0195d895327533648d159b/1cfc2/Home_Assistant_Logitech_Harmony_01.png 900w"],
              "sizes": "(max-width: 900px) 100vw, 900px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5dafaaeba0195d895327533648d159b/1cfc2/Home_Assistant_Logitech_Harmony_01.png",
              "alt": "Logitech Harmony MQTT Control with Home Assistant",
              "title": "Logitech Harmony MQTT Control with Home Assistant",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Sie können jetzt wieder zum `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Logitech_Harmony/"
      }}>{`allgemeinen Tutorial zum Hinzufügen des virtuellen Geräts zu Ihrem Logitech Harmony Hub`}</a>{` wechseln.`}</p>
    <h2 {...{
      "id": "instar-mqtt-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-mqtt-setup",
        "aria-label": "instar mqtt setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Setup`}</h2>
    <p>{`Das virtuelle Gerät Fakeroku gibt Ihnen nun Zugriff auf die folgenden Tasten Ihrer Harmony-Fernbedienung:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b851e5d6852546acbb0fb81e37a93be4/4b446/Home_Assistant_Logitech_Harmony_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVQoz32ST0/CQBDFZ3e2KIqQYpC2mJZCi6X89eIBKMaIwXjw4vf/LM/sZtsoVA8vszPd/mbeZoiI4LBCLxxg//mBXr8PXRNCmPhTbSFxY+vC5q6QuPp9l9BwGhjNczxtN3g7HNBsNs9gWhErTJRTAW+lRKYc+JJ/A7XcdgeB7yOMIqTjBPIExkS4k2yglyRMrkFDVqeN7UEIxMMYQz/Ay2KJOAyrSXSUFjiQbGDCAhPlmDqfAYnQarWw32xxXK7wtdkhcN3qmyIysJiViTr3JBvLQZ3lUkmS4lg843X9iPf5AtfWkgbcaxizebsLEujbBt5/QMWMoigwyTLM8imS0biyrH/UsLJBR8iqQS2wXBXf87Df7hCFEfJpDrfbNW8WKQcz1TCQui2onbBUmk6wXq2RPWTVGukptVX5Nwzfp/jJmpeAresAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b851e5d6852546acbb0fb81e37a93be4/e4706/Home_Assistant_Logitech_Harmony_02.avif 230w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/d1af7/Home_Assistant_Logitech_Harmony_02.avif 460w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/0a597/Home_Assistant_Logitech_Harmony_02.avif 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b851e5d6852546acbb0fb81e37a93be4/a0b58/Home_Assistant_Logitech_Harmony_02.webp 230w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/bc10c/Home_Assistant_Logitech_Harmony_02.webp 460w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/d7666/Home_Assistant_Logitech_Harmony_02.webp 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b851e5d6852546acbb0fb81e37a93be4/81c8e/Home_Assistant_Logitech_Harmony_02.png 230w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/08a84/Home_Assistant_Logitech_Harmony_02.png 460w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/4b446/Home_Assistant_Logitech_Harmony_02.png 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b851e5d6852546acbb0fb81e37a93be4/4b446/Home_Assistant_Logitech_Harmony_02.png",
              "alt": "Logitech Harmony MQTT Control with Home Assistant",
              "title": "Logitech Harmony MQTT Control with Home Assistant",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Zum Beispiel sendet `}<strong parentName="p">{`Button 1`}</strong>{` die Zeichenfolge `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Rev`}</code>{` an den Home Assistant, wenn Sie sie drücken.`}</p>
    <ol>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Rev`}</code>{` - `}<em parentName="li">{`Nicht genutzt`}</em></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Play`}</code>{` - Starte eine `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`x`}</code>{` Sekunden `}<strong parentName="li">{`Videoaufnahme`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Fwd`}</code>{` - `}<em parentName="li">{`Nicht genutzt`}</em></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Home`}</code>{` - Deaktiviere den `}<strong parentName="li">{`Alarm`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Up`}</code>{` - Bewege die Kamera einen Schritt `}<strong parentName="li">{`nach oben`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Right`}</code>{` - Bewege die Kamera einen Schritt `}<strong parentName="li">{`nach rechts`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Down`}</code>{` - Bewege die Kamera einen Schritt `}<strong parentName="li">{`nach unten`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Left`}</code>{` - Bewege die Kamera einen Schritt `}<strong parentName="li">{`nach links`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Select`}</code>{` - Stoppe die Bewegung der Kamera`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Back`}</code>{` - Aktiviere den `}<strong parentName="li">{`Alarm`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Info`}</code>{` - Auslöser aktivierte Alarm-Aktionen`}</li>
    </ol>
    <p>{`Wir müssen jetzt Automatisierungen hinzufügen, die es uns ermöglichen, `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT-Topics auf unserer Kamera zu aktualisieren`}</a>{`, wenn wir eine Nachricht von unserer Harmony-Fernbedienung erhalten. Öffnen Sie dazu das Konfigurationsmenü und fügen Sie eine neue Automatisierung hinzu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "751px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2028f453e7b0e5a3134f520be5799be8/c483d/Home_Assistant_Logitech_Harmony_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABhklEQVQ4y5WU63KrMAyEef+nTEu4Gt9QsC1Zts8AOSW0adN+P4xmYMfyak1VznAu2mcTcikFEe08l1KC9wBQvlDxRlrhyGxDko6lY0COMRIR81F8okLEvu+HYRBCtG0LcGNmihGRXrKKlVJCCKWUlFJrDRtEvxM755Y7tw/i9xziGGMIgZnLr8k538XMrJQchl4q6ZzPuaSU8pnVzDPHzgAwCnFvd+scZtjOAGsN4L1PZw6x985aa7RWSpn1eS+MMVqb3UI8cxhmjLHWeu8fncSHWSFiOLO/rYhoHMfL5bIsy56HlyYfbhORtTPcbtoYvbVg/q9r8xt78WzOIfRt017rrrm213ro+6Zpuq5rN4QQUsppmsZxdM592r8KiJ2y70I3cl2VnedtW2PMy5xVSKQ8K8wq8BRS4JQfpvrx3fMzRyImtBrAegfg3UKIKaU9SU8JIZzmXNfT28U0rZ6EmMQEM+z6p5wS5r1Tyja1aZvRWktIbnE/pP3Idlyv7jr2/Y/wp4vxD/m4UFEfy3pLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2028f453e7b0e5a3134f520be5799be8/e4706/Home_Assistant_Logitech_Harmony_03.avif 230w", "/en/static/2028f453e7b0e5a3134f520be5799be8/d1af7/Home_Assistant_Logitech_Harmony_03.avif 460w", "/en/static/2028f453e7b0e5a3134f520be5799be8/fe9dc/Home_Assistant_Logitech_Harmony_03.avif 751w"],
              "sizes": "(max-width: 751px) 100vw, 751px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2028f453e7b0e5a3134f520be5799be8/a0b58/Home_Assistant_Logitech_Harmony_03.webp 230w", "/en/static/2028f453e7b0e5a3134f520be5799be8/bc10c/Home_Assistant_Logitech_Harmony_03.webp 460w", "/en/static/2028f453e7b0e5a3134f520be5799be8/0d6a7/Home_Assistant_Logitech_Harmony_03.webp 751w"],
              "sizes": "(max-width: 751px) 100vw, 751px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2028f453e7b0e5a3134f520be5799be8/81c8e/Home_Assistant_Logitech_Harmony_03.png 230w", "/en/static/2028f453e7b0e5a3134f520be5799be8/08a84/Home_Assistant_Logitech_Harmony_03.png 460w", "/en/static/2028f453e7b0e5a3134f520be5799be8/c483d/Home_Assistant_Logitech_Harmony_03.png 751w"],
              "sizes": "(max-width: 751px) 100vw, 751px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2028f453e7b0e5a3134f520be5799be8/c483d/Home_Assistant_Logitech_Harmony_03.png",
              "alt": "Logitech Harmony MQTT Control with Home Assistant",
              "title": "Logitech Harmony MQTT Control with Home Assistant",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Up`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87be8d46b8169172b9de531530b16e8b/5a190/Home_Assistant_Logitech_Harmony_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA0UlEQVQoz5WP227DMAhA+/8fmlV1FINNjM3FnpauaaM1lXZe4OUIzkVVmXmapnme9SPyh4uItFoTYs6pVq7MZYMf3PdSyhtZRbjWABgi3hYIEQAgxm3Ac48xvpdLk8B+Y7+uGso/31YVLlRWGt3H6GeMjd57a+0pi8gSY5jnJtLHcPfxETM7yICImHJKCfGeN05+MLM94VcmygBxLWttjWtVM39gR7ZMPTQT0fUrUq7KpRFJa+b+c8Jsr91x91dZiQiWQplzwgSQEIkob5jZmfwN6kdH0jpxlsIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87be8d46b8169172b9de531530b16e8b/e4706/Home_Assistant_Logitech_Harmony_04.avif 230w", "/en/static/87be8d46b8169172b9de531530b16e8b/d1af7/Home_Assistant_Logitech_Harmony_04.avif 460w", "/en/static/87be8d46b8169172b9de531530b16e8b/c9875/Home_Assistant_Logitech_Harmony_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87be8d46b8169172b9de531530b16e8b/a0b58/Home_Assistant_Logitech_Harmony_04.webp 230w", "/en/static/87be8d46b8169172b9de531530b16e8b/bc10c/Home_Assistant_Logitech_Harmony_04.webp 460w", "/en/static/87be8d46b8169172b9de531530b16e8b/d00b9/Home_Assistant_Logitech_Harmony_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87be8d46b8169172b9de531530b16e8b/81c8e/Home_Assistant_Logitech_Harmony_04.png 230w", "/en/static/87be8d46b8169172b9de531530b16e8b/08a84/Home_Assistant_Logitech_Harmony_04.png 460w", "/en/static/87be8d46b8169172b9de531530b16e8b/5a190/Home_Assistant_Logitech_Harmony_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87be8d46b8169172b9de531530b16e8b/5a190/Home_Assistant_Logitech_Harmony_04.png",
              "alt": "Logitech Harmony MQTT Control with Home Assistant",
              "title": "Logitech Harmony MQTT Control with Home Assistant",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"up"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/movestep`}</code></pre></div>
    <h3 {...{
      "id": "schwenken-und-neigen-der-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#schwenken-und-neigen-der-kamera",
        "aria-label": "schwenken und neigen der kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schwenken und Neigen der Kamera`}</h3>
    <p>{`Diese Sequenz hört auf die Schaltflächen `}<strong parentName="p">{`oben`}</strong>{`, `}<strong parentName="p">{`rechts`}</strong>{`, `}<strong parentName="p">{`unten`}</strong>{` und `}<strong parentName="p">{`links`}</strong>{` und aktualisiert das entsprechenden `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`Pan&Tilt MQTT-Topic`}</a>{` auf Ihrer Kamera, wie bereits oben gezeigt.`}</p>
    <h3 {...{
      "id": "schalten-sie-ihren-alarm-scharf-bevor-sie-ihr-haus-verlassen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#schalten-sie-ihren-alarm-scharf-bevor-sie-ihr-haus-verlassen",
        "aria-label": "schalten sie ihren alarm scharf bevor sie ihr haus verlassen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schalten Sie Ihren Alarm scharf, bevor Sie Ihr Haus verlassen`}</h3>
    <p>{`Diese Automatisierung aktiviert/deaktiviert den Alarm auf Ihrer Kamera. Wenn sie scharf gestellt ist, behält Ihre Kamera Ihr Wohnzimmer im Auge. Wenn der Alarm deaktiviert wird, dreht sich die Kamera zur Seite und deaktiviert alle Bewegungserkennungsbereiche. `}<strong parentName="p">{`Hinweis`}</strong>{`: Wenn Sie den kamerainternen PIR-Bewegungssensor in Kombination mit diesen Erfassungsbereichen verwenden, müssen Sie den Sensor ebenfalls deaktivieren, damit dies funktioniert:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "896px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/4c42d/Home_Assistant_Logitech_Harmony_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAuUlEQVQY043QQY7DIAwF0N7/mB0KDQo4uAVjm9JRkmoUKYzUt7DZfPmLi2xqrcaYGGNrTf8hJ5d9EVEIEQD2GeM6/x4hBEQch1U152ytvTt3d857P02T3wBASgkAEJGZR2GRJ/FtKQ7Lz1LgmSuVUgoRfVVbVIglFyFu/f3x2rTWXgeDsKo+8DG55OxcNrXW98ixwhpmltY0LWiui7l6Z+08z8w8DPfeT7VFcs5E66eoau99L3x2vPwLlk/QVetkKTgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1f8fb2d7396bfa016fd15d948d092b4/e4706/Home_Assistant_Logitech_Harmony_05.avif 230w", "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/d1af7/Home_Assistant_Logitech_Harmony_05.avif 460w", "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/c8a6e/Home_Assistant_Logitech_Harmony_05.avif 896w"],
              "sizes": "(max-width: 896px) 100vw, 896px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b1f8fb2d7396bfa016fd15d948d092b4/a0b58/Home_Assistant_Logitech_Harmony_05.webp 230w", "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/bc10c/Home_Assistant_Logitech_Harmony_05.webp 460w", "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/c1a89/Home_Assistant_Logitech_Harmony_05.webp 896w"],
              "sizes": "(max-width: 896px) 100vw, 896px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1f8fb2d7396bfa016fd15d948d092b4/81c8e/Home_Assistant_Logitech_Harmony_05.png 230w", "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/08a84/Home_Assistant_Logitech_Harmony_05.png 460w", "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/4c42d/Home_Assistant_Logitech_Harmony_05.png 896w"],
              "sizes": "(max-width: 896px) 100vw, 896px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b1f8fb2d7396bfa016fd15d948d092b4/4c42d/Home_Assistant_Logitech_Harmony_05.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "889px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/04cb096569f04a65b121d68d5722ff68/83e77/Home_Assistant_Logitech_Harmony_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABV0lEQVQ4y42T65LCIAxGff83XWspJCQQbt0BlK0tznrGXw4hXw/JLTRExDkXQvyG8OIWDsQYc845pTyDmVuDcC4WkWVZVqVKyaWxX2jXplLKpNgYg2griMxcSumnR+fxz0j+V7xVlHNMRF5k3/ce4Zi8V56LYwx6g/sPOGIH4Cy5hrRbTkyEtcCeidkSE3HDe3+KnXO+FktKsZSccv3VfI0Y0zjabXvvJ8WPx0OpbfS5po0xisikc7ddVTVCCOUD82KllNoUAGitLdF4mCNjls7CAFApTCEmkRxCPz3N/95Z6mVgUK3OgkWtLSARWbTOuf+fSkTaYL9moAXr8eRANyIiZ9taa0AcVqa2nHOTrarjqTdEHG72z0yK16Zbb3pdV2wRrlsZD59ziC0CAMaYajjG50qX6V6/v7M028bgcmc0gJtCgDrZRN/aZuan0ma1j/FxkgfD9i9dzJQzmgG17gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04cb096569f04a65b121d68d5722ff68/e4706/Home_Assistant_Logitech_Harmony_06.avif 230w", "/en/static/04cb096569f04a65b121d68d5722ff68/d1af7/Home_Assistant_Logitech_Harmony_06.avif 460w", "/en/static/04cb096569f04a65b121d68d5722ff68/83101/Home_Assistant_Logitech_Harmony_06.avif 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/04cb096569f04a65b121d68d5722ff68/a0b58/Home_Assistant_Logitech_Harmony_06.webp 230w", "/en/static/04cb096569f04a65b121d68d5722ff68/bc10c/Home_Assistant_Logitech_Harmony_06.webp 460w", "/en/static/04cb096569f04a65b121d68d5722ff68/ade25/Home_Assistant_Logitech_Harmony_06.webp 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04cb096569f04a65b121d68d5722ff68/81c8e/Home_Assistant_Logitech_Harmony_06.png 230w", "/en/static/04cb096569f04a65b121d68d5722ff68/08a84/Home_Assistant_Logitech_Harmony_06.png 460w", "/en/static/04cb096569f04a65b121d68d5722ff68/83e77/Home_Assistant_Logitech_Harmony_06.png 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/04cb096569f04a65b121d68d5722ff68/83e77/Home_Assistant_Logitech_Harmony_06.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "starten-sie-eine-manuelle-videoaufzeichnung",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#starten-sie-eine-manuelle-videoaufzeichnung",
        "aria-label": "starten sie eine manuelle videoaufzeichnung permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starten Sie eine manuelle Videoaufzeichnung`}</h3>
    <p>{`Diese Automatisierung ermöglicht es Ihnen, eine manuelle Videoaufnahme zu starten, wenn Sie die `}<strong parentName="p">{`Play`}</strong>{`-Taste auf Ihrer Fernbedienung drücken. Beachten Sie, dass der Verzögerungsknoten derzeit auf 30 Sekunden eingestellt ist. Sie können die Länge der Aufnahme durch Ändern dieses Wertes anpassen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "895px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0282249ec7e1d52ca831cc5b648d973b/fcbaf/Home_Assistant_Logitech_Harmony_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAyUlEQVQoz5WRyW7EIAxA5/9/MxCcDQII4i1NFaJu04zUvoMPlp8t2w9mRkQA8N6LCP8ZRHwwMxFN0xRjvFL0E2z87nvKIlJK6XsLzllj+oa11jlnrQWAYRiMMTnnJ7/JzHXbYJrHxcO8zD6EEFoIOWf8xt1k5ko8Vhk3gcK+svxr53NtxBRrLXR88HbHvu9E9CyXUsCtppuDX1OKOWVVPe5Q1cs/ZSISkZSS6YLtFtOZdQ2IeLzgc/jXq2qt7SQiIqoqKq+46i/5HWAzCeEiwSz5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0282249ec7e1d52ca831cc5b648d973b/e4706/Home_Assistant_Logitech_Harmony_07.avif 230w", "/en/static/0282249ec7e1d52ca831cc5b648d973b/d1af7/Home_Assistant_Logitech_Harmony_07.avif 460w", "/en/static/0282249ec7e1d52ca831cc5b648d973b/d176d/Home_Assistant_Logitech_Harmony_07.avif 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0282249ec7e1d52ca831cc5b648d973b/a0b58/Home_Assistant_Logitech_Harmony_07.webp 230w", "/en/static/0282249ec7e1d52ca831cc5b648d973b/bc10c/Home_Assistant_Logitech_Harmony_07.webp 460w", "/en/static/0282249ec7e1d52ca831cc5b648d973b/dcb2d/Home_Assistant_Logitech_Harmony_07.webp 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0282249ec7e1d52ca831cc5b648d973b/81c8e/Home_Assistant_Logitech_Harmony_07.png 230w", "/en/static/0282249ec7e1d52ca831cc5b648d973b/08a84/Home_Assistant_Logitech_Harmony_07.png 460w", "/en/static/0282249ec7e1d52ca831cc5b648d973b/fcbaf/Home_Assistant_Logitech_Harmony_07.png 895w"],
              "sizes": "(max-width: 895px) 100vw, 895px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0282249ec7e1d52ca831cc5b648d973b/fcbaf/Home_Assistant_Logitech_Harmony_07.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/681f1/Home_Assistant_Logitech_Harmony_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCElEQVQoz3WS23qEIAyE9/0fdC1ol5OEHAi2Sutqq/9FvtxMJhl48Ia1dhxHZpYb+B+I+OhdinGOEQEKXJBzJqJrMTFPIRkXh1ewzocQvF/r3njvEfHW2WN9YXWss7Rlo7XWa2tNVWut/agLMRN+2hAdYE6z85AS/lJr7eOYuY84iUUEAMYxTtM8TaM11hprjM05I6KILAdUtd//dkZEIhJhuQr8Z7lNsyd3EsdYKAPFqLW2M8uyEBEAXKzdu37SarTV45sfQ75wJkLn5jyTIFEGRlTVrqy1qmprrRv8FYtIKWCGYD7SZI15Ps0wGGNSTADA58D2zd/OZaUPXrNDpFJKt737p9/iL7ILuq6B7Jn6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6a01c8c8c289609322abf1a5d2a67b8/e4706/Home_Assistant_Logitech_Harmony_08.avif 230w", "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/d1af7/Home_Assistant_Logitech_Harmony_08.avif 460w", "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/06569/Home_Assistant_Logitech_Harmony_08.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6a01c8c8c289609322abf1a5d2a67b8/a0b58/Home_Assistant_Logitech_Harmony_08.webp 230w", "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/bc10c/Home_Assistant_Logitech_Harmony_08.webp 460w", "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/8bcb7/Home_Assistant_Logitech_Harmony_08.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6a01c8c8c289609322abf1a5d2a67b8/81c8e/Home_Assistant_Logitech_Harmony_08.png 230w", "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/08a84/Home_Assistant_Logitech_Harmony_08.png 460w", "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/681f1/Home_Assistant_Logitech_Harmony_08.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6a01c8c8c289609322abf1a5d2a67b8/681f1/Home_Assistant_Logitech_Harmony_08.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "einen-alarm-auslösen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#einen-alarm-ausl%C3%B6sen",
        "aria-label": "einen alarm auslösen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Einen Alarm auslösen`}</h3>
    <p>{`Und nun zur einfachsten Sequenz. Durch Drücken der Taste `}<strong parentName="p">{`Info`}</strong>{` wird ein Alarm auf Ihrer Kamera ausgelöst. Dieses Ereignis löst alle aktiven Alarmaktionen auf Ihrer Kamera aus - wie z.B. die Videoaufzeichnungen, E-Mail-Benachrichtigungen, FTP/Cloud-Uploads usw.:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "896px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6caa25fe876ca690d5cc04392f1f9c51/4c42d/Home_Assistant_Logitech_Harmony_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAz0lEQVQoz5WRy3LDIAxF/f/f2C5iQw0YEyIID0nk4bhNOonbTs9KC52RdNURUc5ZCGGMISL8Dx0i1loBIMa41ivf61LKtkxExxiHvh8/ZL/bSSnkF8MwKKW01kIIAHjda5FTyqOZlZ2VddPs/H7BORdCuE/eHN4RYqpoEtvCOrMv1PiTPyNYbl56SomQjpBPN9qN8xZrHA8ZEUOMUvr3t1Er4/3+cABm3pSZ+SHXWokoQDAapilYO4UAiHj+gdba86tSSjmXNZ7fr70/8ipfAH7dChLlrfr/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6caa25fe876ca690d5cc04392f1f9c51/e4706/Home_Assistant_Logitech_Harmony_09.avif 230w", "/en/static/6caa25fe876ca690d5cc04392f1f9c51/d1af7/Home_Assistant_Logitech_Harmony_09.avif 460w", "/en/static/6caa25fe876ca690d5cc04392f1f9c51/c8a6e/Home_Assistant_Logitech_Harmony_09.avif 896w"],
              "sizes": "(max-width: 896px) 100vw, 896px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6caa25fe876ca690d5cc04392f1f9c51/a0b58/Home_Assistant_Logitech_Harmony_09.webp 230w", "/en/static/6caa25fe876ca690d5cc04392f1f9c51/bc10c/Home_Assistant_Logitech_Harmony_09.webp 460w", "/en/static/6caa25fe876ca690d5cc04392f1f9c51/c1a89/Home_Assistant_Logitech_Harmony_09.webp 896w"],
              "sizes": "(max-width: 896px) 100vw, 896px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6caa25fe876ca690d5cc04392f1f9c51/81c8e/Home_Assistant_Logitech_Harmony_09.png 230w", "/en/static/6caa25fe876ca690d5cc04392f1f9c51/08a84/Home_Assistant_Logitech_Harmony_09.png 460w", "/en/static/6caa25fe876ca690d5cc04392f1f9c51/4c42d/Home_Assistant_Logitech_Harmony_09.png 896w"],
              "sizes": "(max-width: 896px) 100vw, 896px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6caa25fe876ca690d5cc04392f1f9c51/4c42d/Home_Assistant_Logitech_Harmony_09.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "894px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cb6dca6141563c46ead62fca63e73ade/38af3/Home_Assistant_Logitech_Harmony_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAnUlEQVQY03XQWw6DIBAF0O5/nwJWogKjZV6xAVJtq54PuAm5mQwPqvq+N8ZmRK5EZA8tiwidPNq1LgApvhYoASClBCe35SGAnWI3JjPGEGMM5fhzWw6ZZ5RAOqOKHuRDVdsiF2VhmnxIc1ZEXBfKWWpTVbdf3/1SZmZEdG40BvzwdKZz1lprBu+ZaTsREUQ8JudK25cyI1F55mv75DfOUpcXhIRi0wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb6dca6141563c46ead62fca63e73ade/e4706/Home_Assistant_Logitech_Harmony_10.avif 230w", "/en/static/cb6dca6141563c46ead62fca63e73ade/d1af7/Home_Assistant_Logitech_Harmony_10.avif 460w", "/en/static/cb6dca6141563c46ead62fca63e73ade/542e7/Home_Assistant_Logitech_Harmony_10.avif 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cb6dca6141563c46ead62fca63e73ade/a0b58/Home_Assistant_Logitech_Harmony_10.webp 230w", "/en/static/cb6dca6141563c46ead62fca63e73ade/bc10c/Home_Assistant_Logitech_Harmony_10.webp 460w", "/en/static/cb6dca6141563c46ead62fca63e73ade/26e8f/Home_Assistant_Logitech_Harmony_10.webp 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb6dca6141563c46ead62fca63e73ade/81c8e/Home_Assistant_Logitech_Harmony_10.png 230w", "/en/static/cb6dca6141563c46ead62fca63e73ade/08a84/Home_Assistant_Logitech_Harmony_10.png 460w", "/en/static/cb6dca6141563c46ead62fca63e73ade/38af3/Home_Assistant_Logitech_Harmony_10.png 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cb6dca6141563c46ead62fca63e73ade/38af3/Home_Assistant_Logitech_Harmony_10.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "schlussfolgerung",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#schlussfolgerung",
        "aria-label": "schlussfolgerung permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schlussfolgerung`}</h2>
    <p>{`Dies ist nur ein kleiner Überblick aller `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`verfügbaren MQTT-Topics`}</a>{` auf Ihrer Kamera. Alle diese Topics können mit einem Tastendruck auf Ihrer Logitech Harmony-Fernbedienung kombiniert und gesendet werden. Weitere Informationen zur Verwendung der `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
      }}>{`INSTAR MQTT-Schnittstelle mit Home Assistant finden Sie in unserem vollständigen Tutorial`}</a>{`.`}</p>
    <h2 {...{
      "id": "automationsyaml-export",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#automationsyaml-export",
        "aria-label": "automationsyaml export permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`automations.yaml export`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583746741522'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony PT Up
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Tilt camera up when Up is pressed on my Harmony remote '`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Up
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"up"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/movestep
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583747187870'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony PT Down
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`' '`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Down
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"down"}'`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/movestep
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583747246045'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony PT Left
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Left
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"left"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/movestep
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583747337674'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony PT Right
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Right
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"right"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/movestep
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583748047823'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony I am Home
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Deactivate alarm areas and turn around
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area1/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area2/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area3/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area4/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"0"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583748225541'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony I am Leaving
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Activate all alarm areas and keep an eye on my living room
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Back
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/ptz/preset
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area1/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area2/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area3/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/area4/enable
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583748469538'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony trigger an alarm
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Info
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"1"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/alarm/pushalarm
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1583749140147'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Harmony record a video
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Play
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Home Assistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` keypress
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` roku_command
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` event
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`condition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"60"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/manualrec/start
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`delay`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token datetime number"
          }}>{`00:00:30`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"off"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar/local/features/manualrec/stop
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` script.send_mqtt_command`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      